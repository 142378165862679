// Vendors
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
// Context
import { ActionType } from '@context/ActionTypes';
import { MessagingState } from '@context/Context';
// Services
import FolderService from '@services/FolderService';
// Hooks
import { useDiscussionThreads } from '@hooks/useDiscussionThreads';
// Components
import SearchResultPagination from './SearchResultPagination';

const SearchResultList = () => {
    const { t } = useTranslation();
    const {
        state: { searchPayload },
        dispatch,
    } = MessagingState();
    const { getDiscussionThreads } = useDiscussionThreads();
    const { folderParam, pageNumber } = useParams();
    const navigate = useNavigate();

    const handleCloseBtnClick = () => {
        dispatch({
            type: ActionType.SET_SEARCH_PAYLOAD,
            payload: { ...searchPayload, searchText: '' },
        });
        dispatch({
            type: ActionType.SET_SEARCH_FOCUS,
            payload: false,
        });
        dispatch({
            type: ActionType.SET_SEARCH_DISCUSSIONTHREADS,
            payload: [],
        });
        const folder = FolderService.getAll().find(folder => folder.path === folderParam) || FolderService.getAll()[0];

        getDiscussionThreads(folder);
        navigate(`/${folderParam}/page/${pageNumber}`);
    };
    return (
        <ul
            aria-labelledby="searchResultList"
            className="list-group full-height">
            <li className="list-group-item list-group-title">
                <div className="row">
                    <div className="col-md col-sm">
                        <button
                            className="close"
                            aria-label={t('labels.closeSearchResults')}
                            type="button"
                            tabIndex={0}
                            onClick={handleCloseBtnClick}
                        />
                        <span className="discussion-thread-list-group-title">{t('searchResults')}</span>
                    </div>
                </div>
            </li>
            <SearchResultPagination
                searchText={searchPayload.searchText}
                folderSelection={searchPayload.folder.folderSelection}
            />
        </ul>
    );
};

export default SearchResultList;
