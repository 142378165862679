// Vendors
import React, { useCallback, useEffect, useRef } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
// Context
import { MessagingState } from '@context/Context';
import { ActionType } from '@context/ActionTypes';
// Types
import { IDiscussionThreadWithMessageIds, IPagedRequestPayload } from '@root/@types/discussionthread';
import { IMessageWithContent, IMessageWithId } from '../@types/message';
// Services
import DiscussionThreadService from '@services/DiscussionThreadService';
// Hooks
import { useUpdateInboxUnreadCount } from './hooks/useUpdateInboxUnreadCount';
// Other
import { getThreadUnreadMessages } from '@common-utils';
import Message from '@root/components/Message';
import { LoadingState } from '@root/@types/loadingstates';
import { BiInfiniteScroller } from '@root/components/InfiniteScroller';
import { Spinner } from '@vismaux/react-vud';
import { useToast } from '@root/context/ToastContext';
import { useTranslation } from 'react-i18next';

const MessageList: React.FC = () => {
    const {
        state: { activeDiscussionThread, activeThreadMessages },
        dispatch,
    } = MessagingState();
    const { updateInboxUnreadCount } = useUpdateInboxUnreadCount();
    //TODO zero until pagination canReplyToThisMessage solution is found, currently frontend only supports one page of messages
    const threadId = activeDiscussionThread?.id;
    const hasUnreadMessages =
        (activeDiscussionThread &&
            activeThreadMessages &&
            getThreadUnreadMessages(activeThreadMessages, activeDiscussionThread.lastReadMessageId).length > 0) ||
        false;
    const messageListRef = useRef<HTMLDivElement>(null);
    const { createToast } = useToast();
    const { t } = useTranslation();

    useEffect(() => {
        if (!threadId || !activeThreadMessages || !activeDiscussionThread) {
            return;
        }

        async function updateReadStatus(
            id: number,
            totalMessages: IMessageWithId[],
            messages: IMessageWithContent[],
            lastReadMessageId?: number
        ) {
            const latestMessage = messages.slice(-1)[0];
            const unreadMessages = lastReadMessageId
                ? getThreadUnreadMessages(totalMessages, lastReadMessageId)
                : totalMessages.map(m => m.id);
            if (unreadMessages.includes(latestMessage.id)) {
                const responseOk = await DiscussionThreadService.setMessageAsRead(id, latestMessage.id);
                if (responseOk) {
                    dispatch({
                        type: ActionType.SET_MESSAGE_AS_READ_SUCCESS,
                        payload: {
                            threadId: id,
                            messageId: latestMessage.id,
                        },
                    });

                    if (location.search.includes('search')) {
                        dispatch({
                            type: ActionType.SET_SEARCHED_THREAD_MESSAGE_AS_READ_SUCCESS,
                            payload: {
                                threadId: id,
                                messageId: latestMessage.id,
                            },
                        });
                    }
                }
                // Re-fetch inbox unread count when marking thread as read, naively.
                // This might desync the count and showing received threads, when for example someone sends a new thread while the user is already
                // reading another unread thread. The read thread is marked read, but the count will be increased and no new threads would be shown.
                // Refactor this later, use for example WebSockets to communicate current thread listing statuses to frontend.
                updateInboxUnreadCount();
            }
        }
        updateReadStatus(
            threadId,
            activeDiscussionThread.messages,
            activeThreadMessages,
            activeDiscussionThread.lastReadMessageId
        );
        amplitude.track('message_opened', {
            discussion_thread_id: threadId,
        });
    }, [dispatch, threadId, activeDiscussionThread, updateInboxUnreadCount, activeThreadMessages]);

    const currentNewestMessageIndex = useCallback(
        (threadMessages: IMessageWithContent[], thread: IDiscussionThreadWithMessageIds) => {
            const currentNewestLoadedMessageId = threadMessages[threadMessages.length - 1].id;
            return thread.messages.findIndex(m => m.id === currentNewestLoadedMessageId);
        },
        []
    );

    const hasNext = useCallback(
        (threadMessages: IMessageWithContent[], thread: IDiscussionThreadWithMessageIds) => {
            return currentNewestMessageIndex(threadMessages, thread) !== thread.messages.length - 1;
        },
        [currentNewestMessageIndex]
    );

    const currentOldestMessageIndex = useCallback(
        (threadMessages: IMessageWithContent[], thread: IDiscussionThreadWithMessageIds) => {
            const currentOldestLoadedMessageId = threadMessages[0].id;
            return thread.messages.findIndex(m => m.id === currentOldestLoadedMessageId);
        },
        []
    );

    const hasPrevious = useCallback(
        (threadMessages: IMessageWithContent[], thread: IDiscussionThreadWithMessageIds) => {
            return currentOldestMessageIndex(threadMessages, thread) !== 0;
        },
        [currentOldestMessageIndex]
    );

    const fetchNewerMessages = useCallback(async () => {
        if (!activeDiscussionThread || !activeThreadMessages) return [];
        if (!hasNext(activeThreadMessages, activeDiscussionThread)) {
            return;
        }
        const newestMessageIndex = currentNewestMessageIndex(activeThreadMessages, activeDiscussionThread);
        const newerMessageIdIndex = Math.min(activeDiscussionThread.messages.length - 1, newestMessageIndex + 6);
        let fetchMessageIds = activeThreadMessages.map(m => m.id);
        fetchMessageIds = fetchMessageIds.concat(
            activeDiscussionThread.messages.slice(newestMessageIndex + 1, newerMessageIdIndex + 1).map(m => m.id)
        );
        try {
            const pagedPayload: IPagedRequestPayload = {
                pageNumber: 1,
                pageSize: 0,
            };
            const messageResponse = await DiscussionThreadService.postThreadMessages(
                activeDiscussionThread.id,
                { messageIds: fetchMessageIds },
                pagedPayload
            );
            dispatch({
                type: ActionType.SET_ACTIVE_DISCUSSIONTHREAD,
                payload: {
                    thread: activeDiscussionThread,
                    activeThreadMessages: messageResponse.messages,
                    loadingState: LoadingState.Done,
                },
            });
            dispatch({
                type: ActionType.SCROLL_MESSAGE_LIST_TO_BOTTOM,
                payload: true,
            });
        } catch {
            console.error('Error fetching newer messages');
            createToast({
                title: t('errors.messageLoadingError'),
                toastType: 'danger',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDiscussionThread, activeThreadMessages, hasNext, currentNewestMessageIndex, dispatch]);

    const fetchOlderMessages = useCallback(async () => {
        if (!activeDiscussionThread || !activeThreadMessages) return [];
        if (!hasPrevious(activeThreadMessages, activeDiscussionThread)) {
            return;
        }
        const oldestMessageIndex = currentOldestMessageIndex(activeThreadMessages, activeDiscussionThread);
        const olderMessageIdIndex = Math.max(0, oldestMessageIndex - 6);
        const olderFetchMessageIds = activeDiscussionThread.messages
            .slice(olderMessageIdIndex, oldestMessageIndex)
            .map(m => m.id);
        const fetchMessageIds = olderFetchMessageIds.concat(activeThreadMessages.map(m => m.id));
        try {
            const pagedPayload: IPagedRequestPayload = {
                pageNumber: 1,
                pageSize: 0,
            };
            const messageResponse = await DiscussionThreadService.postThreadMessages(
                activeDiscussionThread.id,
                { messageIds: fetchMessageIds },
                pagedPayload
            );
            dispatch({
                type: ActionType.SET_ACTIVE_DISCUSSIONTHREAD,
                payload: {
                    thread: activeDiscussionThread,
                    activeThreadMessages: messageResponse.messages,
                    loadingState: LoadingState.Done,
                },
            });
        } catch {
            console.error('Error fetching older messages');
            createToast({
                title: t('errors.messageLoadingError'),
                toastType: 'danger',
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeDiscussionThread, activeThreadMessages, currentOldestMessageIndex, dispatch, hasPrevious]);

    const loadingElement = () => {
        return (
            <div className="message-loading-indicator">
                <Spinner />
            </div>
        );
    };

    return (
        activeDiscussionThread &&
        activeThreadMessages && (
            <BiInfiniteScroller
                id={'message-list'}
                className="message-list"
                ref={messageListRef}
                tabIndex={0}
                fetchNextPage={fetchNewerMessages}
                fetchPreviousPage={fetchOlderMessages}
                hasNextPage={hasNext(activeThreadMessages, activeDiscussionThread)}
                hasPreviousPage={hasPrevious(activeThreadMessages, activeDiscussionThread)}
                loadingIndicator={loadingElement()}
                scrollToBottom={!hasUnreadMessages}>
                {activeThreadMessages?.map(message => (
                    <Message
                        threadMessages={activeThreadMessages}
                        message={message}
                        key={message.id}
                        activeDiscussionThread={activeDiscussionThread}
                    />
                ))}
            </BiInfiniteScroller>
        )
    );
};

export default MessageList;
