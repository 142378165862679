// Vendors
import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
// Types
import { IUser } from '@appTypes/user';
import { IMessageWithContent } from '@appTypes/message';
// Other
import { getUserDisplayName } from '@common-utils';
import { IDiscussionThreadWithMessageIds } from '@root/@types/discussionthread';

interface Props {
    thread: IDiscussionThreadWithMessageIds;
    threadMessages: IMessageWithContent[];
    message: IMessageWithContent;
    index?: number;
    showLastAdded?: boolean;
}

export const getAddedRecipients = (
    threadMessages: IMessageWithContent[],
    currentMessage: IMessageWithContent,
    thread: IDiscussionThreadWithMessageIds,
    messageIndex?: number,
    showLastAdded?: boolean
) => {
    // If message is the first one, everyone initially added can be ignored
    if (messageIndex === 0) return [];

    // If message is the last one, return all recipients added after the current message
    if (showLastAdded) {
        const messageTime = dayjs(currentMessage.sentAt);
        return thread.recipients.filter((threadUser: IUser) => {
            const addedAtDate = threadUser.addedAt ? dayjs(threadUser.addedAt) : 0;
            return addedAtDate && addedAtDate.isAfter(messageTime);
        });
    }
    // Convert the message.sentAt to a timestamp value for easy comparison
    const messageTime = dayjs(currentMessage.sentAt);

    // Filter the recipients to find those added after the current message
    const addedRecipients = thread.recipients.filter((threadUser: IUser) => {
        // Convert the threadUser.addedAt to a timestamp value for easy comparison
        const addedAtDate = threadUser.addedAt ? dayjs(threadUser.addedAt) : 0;
        // Check if the recipient was added before the current message
        if (addedAtDate && addedAtDate.isBefore(messageTime)) {
            // Previous message is guaranteed when we skip first index
            const prevMessage = messageIndex && threadMessages[messageIndex - 1];
            const prevMessageTime = prevMessage ? dayjs(prevMessage.sentAt) : 0;

            // Check if the recipient's addedAtDate is after the previous message
            return addedAtDate.isAfter(prevMessageTime);
        }
        // If the recipient was not added before the current message or it's the first message, exclude it
        return false;
    });
    return addedRecipients;
};

export const DiscussionThreadUserAdded = (props: Props) => {
    const { t } = useTranslation();

    return (
        <div>
            {/* Check for added recipients and render their messages */}
            {getAddedRecipients(props.threadMessages, props.message, props.thread, props.index, props.showLastAdded).map(
                (recipient: IUser) => (
                    <div
                        key={`${recipient.roleGuid}`}
                        className="user-added-container">
                        <p>{t('userAddedMessage', { fullName: `${getUserDisplayName(recipient)}` })}</p>
                    </div>
                )
            )}
        </div>
    );
};
