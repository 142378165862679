import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { IMessageWithContent } from '../@types/message';
import { IContent } from '@root/@types/content';
import { threadHasMessagesFromMultipleAuthors, getUserDisplayName, getSentAtMessage } from '@root/utils/utils';
import { Icon } from '@vismaux/react-vud';
import { DiscussionThreadUserAdded } from './DiscussionThreadUserAdded';
import { MessagingState } from '@root/context/Context';
import { IUser } from '@root/@types/user';
import withReplyHandling, { WithReplyHandlingProps } from './hoc/withReplyHandling';
import { IDiscussionThread, IDiscussionThreadWithMessageIds } from '@root/@types/discussionthread';

interface MessageProps extends WithReplyHandlingProps {
    threadMessages: IMessageWithContent[];
    message: IMessageWithContent;
    activeDiscussionThread: IDiscussionThread | IDiscussionThreadWithMessageIds;
}

const Message: React.FC<MessageProps> = ({ message, threadMessages, replyTo, activeDiscussionThread }) => {
    const { t } = useTranslation();
    const {
        state: { currentUser, unreadMessages },
    } = MessagingState();
    const index = activeDiscussionThread.messages.findIndex(m => m.id === message.id);
    const loadedIndex = threadMessages.findIndex(m => m.id === message.id);
    const messageNewRef = useRef<null | HTMLDivElement>(null);
    const replyToThisMessage = (messageId: number, userToReplyTo: IUser) => {
        replyTo(messageId, userToReplyTo);
    };

    useEffect(() => {
        if (messageNewRef.current != null) {
            messageNewRef?.current?.scrollIntoView({ block: 'end' });
            messageNewRef?.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
    }, [activeDiscussionThread]);
    const messageDivClass = classNames({
        message: threadMessages.length <= 1,
        'bubble-right': threadMessages.length > 1 && message.author.roleGuid === currentUser?.roleGuid,
        'bubble-left': threadMessages.length > 1 && message.author.roleGuid !== currentUser?.roleGuid,
    });

    const getReplyButton = (message: IMessageWithContent) => {
        return (
            <button
                type="button"
                onClick={() => replyToThisMessage(message.id, message.author)}
                className="btn btn-default icon-button reply-button-right">
                <Icon
                    name="send"
                    dynamic
                    size="sm"
                />
            </button>
        );
    };

    const renderUnreadMessagesContent = () => {
        return (
            <div
                key={'unreadMessages'}
                className="unread-container"
                ref={messageNewRef}>
                <hr />
                <div className="unread">{t('message.newMessage', { count: unreadMessages.length })} </div>
            </div>
        );
    };

    return (
        <React.Fragment key={message.id}>
            {index > 0 &&
                unreadMessages.length > 0 &&
                activeDiscussionThread.lastReadMessageId === activeDiscussionThread.messages[index - 1].id &&
                renderUnreadMessagesContent()}
            <DiscussionThreadUserAdded
                threadMessages={threadMessages}
                thread={activeDiscussionThread}
                message={message}
                index={loadedIndex}
            />
            <div className={messageDivClass}>
                {threadHasMessagesFromMultipleAuthors(threadMessages, activeDiscussionThread.author?.roleGuid) &&
                    message.canReplyToThisMessage &&
                    threadMessages.length > 1 &&
                    getReplyButton(message)}
                {message.contents.map((content: IContent, index: number) => {
                    return (
                        <div
                            className="container-fluid"
                            key={index}>
                            {threadMessages.length > 1 && (
                                <div className="row">
                                    <div className="col-6">
                                        <b>{getUserDisplayName(message.author)}</b>
                                    </div>
                                    <div className="col">
                                        <p className="float-right">{getSentAtMessage(message.sentAt)}</p>
                                    </div>
                                </div>
                            )}
                            <div className="row">
                                <div className="col-md-12">
                                    <span>{content.contentString}</span>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            {index === activeDiscussionThread.messages.length - 1 && (
                <DiscussionThreadUserAdded
                    threadMessages={threadMessages}
                    thread={activeDiscussionThread}
                    message={message}
                    showLastAdded
                />
            )}
        </React.Fragment>
    );
};

export default withReplyHandling(Message);
