// Vendors
import dayjs from 'dayjs';
import React, { createContext, useContext, useReducer } from 'react';
// Context
import { MessagingContextActions, messagingReducer } from './Reducers';
// Types
import { IUser } from '@appTypes/user';
import { IFolder } from '@appTypes/folder';
import { IMessageWithContent } from '@appTypes/message';
import { ISearchResult } from '@appTypes/search';
import { Loading, LoadingState } from '@appTypes/loadingstates';
import {
    IDiscussionThread,
    IDiscussionThreadSelectPayload,
    IDiscussionThreadWithMessageIds,
} from '@appTypes/discussionthread';
import { IIndexRecords, IModalRecipient, IPrimusSearches, IRecipientSelectionData } from '@appTypes/recipients';
// Services
import FolderService, { FolderSelection } from '@services/FolderService';
// Components
import { StringOption } from '@components/WilmaAsyncSelect/WilmaAsyncSelect';
import { RecipientSelectionTypes } from '@components/SelectRecipients/RecipientsPersonList';

export interface IMessagingContextState {
    folders: IFolder[];
    discussionThreads: IDiscussionThread[] | IDiscussionThreadWithMessageIds[];
    unreadInboxCount: number;
    unreadInboxCountLoading: LoadingState;
    searchResponses: ISearchResult[] | null;
    activeDiscussionThread: IDiscussionThread | IDiscussionThreadWithMessageIds | null;
    activeThreadMessages: IMessageWithContent[] | undefined;
    threadLoadingState: LoadingState;
    errorCanceled: boolean;
    unreadMessages: number[];
    currentUser: IUser | undefined;
    lastActivityTimestamp: dayjs.Dayjs;
    lastRefreshTime: dayjs.Dayjs;
    lastSentMessageData: {
        threadId: number | null;
        canCancelThread: boolean;
    };
    searchFocus: boolean;
    searchPayload: {
        searchText: string;
        folder: IFolder;
    };
    selectedRecipients: { options: StringOption[] };
    modalRecipients: { modalRecipientOptions: IModalRecipient[] };
    message: IDiscussionThreadSelectPayload;
    recipientListData: IRecipientSelectionData;
    generalRecipientData: IIndexRecords & Loading;
    primusSearches: IPrimusSearches;
    selectedSchoolId: string;
    activeButtonId: string;
    modalHiddenRecipientLabels: string[];
    paginationData: IContextPaginationData;
    discussionThreadsLoading: LoadingState;
    isReplyBoxVisible: boolean;
    isReplyToAuthor: boolean;
    replyInfo: { messageId: number; userToReplyTo: IUser | null };
    scrollToBottomTrigger: boolean;
}

export interface IContextPaginationData {
    currentPage: number;
    totalPages: number;
}

interface ProviderProps {
    children: React.ReactNode;
    preloadedState?: Partial<IMessagingContextState>;
}

export const initialState: IMessagingContextState = {
    folders: [],
    discussionThreads: [],
    unreadInboxCount: 0,
    unreadInboxCountLoading: LoadingState.Loading,
    searchResponses: null,
    activeDiscussionThread: null,
    activeThreadMessages: [],
    threadLoadingState: LoadingState.Loading,
    errorCanceled: false,
    unreadMessages: [],
    currentUser: undefined,
    lastActivityTimestamp: dayjs(),
    lastRefreshTime: dayjs(),
    lastSentMessageData: {
        threadId: null,
        canCancelThread: false,
    },
    searchFocus: false,
    searchPayload: {
        searchText: '',
        folder: FolderService.getAll()[FolderSelection.All],
    },
    selectedRecipients: { options: [] },
    modalRecipients: { modalRecipientOptions: [] },
    message: {
        title: '',
        recipients: [],
        message: '',
        seeNames: false,
        seeResponses: false,
    },
    recipientListData: {
        showRecipientList: RecipientSelectionTypes.None,
        records: [],
    },
    generalRecipientData: {
        indexRecords: [],
        loadingState: LoadingState.Loading,
    },
    primusSearches: {
        studentRecords: [],
        teacherRecords: [],
        personnelRecords: [],
        workplaceInstructorRecords: [],
        trainingCoordinatorRecords: [],
    },
    selectedSchoolId: '0',
    activeButtonId: '',
    modalHiddenRecipientLabels: [],
    paginationData: { currentPage: 1, totalPages: 1 },
    discussionThreadsLoading: LoadingState.Loading,
    isReplyBoxVisible: false,
    isReplyToAuthor: false,
    replyInfo: { messageId: 0, userToReplyTo: null },
    scrollToBottomTrigger: false,
};

const MessagingContext = createContext<{
    state: IMessagingContextState;
    dispatch: React.Dispatch<MessagingContextActions>;
}>({
    state: initialState,
    dispatch: () => null,
});

const MessagingProvider = (props: ProviderProps) => {
    const [state, dispatch] = useReducer(messagingReducer, {
        ...initialState,
        ...props.preloadedState,
    });
    const value = { state, dispatch };
    return <MessagingContext.Provider value={value}>{props.children}</MessagingContext.Provider>;
};

export default MessagingProvider;

export const MessagingState = () => {
    return useContext(MessagingContext);
};
