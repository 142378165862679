import { ActionType } from '@root/context/ActionTypes';
import { MessagingState } from '@root/context/Context';
import React, { MutableRefObject, useEffect, forwardRef, PropsWithChildren, useRef, useImperativeHandle } from 'react';
import { useInView } from 'react-intersection-observer';

// Inspired by https://github.com/Apestein/better-react-infinite-scroll and adapted parts from it
interface BiInfiniteScrollProps extends React.HTMLAttributes<HTMLDivElement> {
    fetchNextPage: () => Promise<unknown>;
    fetchPreviousPage: () => Promise<unknown>;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    loadingIndicator: React.ReactNode;
    scrollToBottom: boolean;
}

export const BiInfiniteScroller = forwardRef<HTMLDivElement, PropsWithChildren<BiInfiniteScrollProps>>(
    function BiInfiniteScroller(
        {
            fetchNextPage,
            fetchPreviousPage,
            hasNextPage,
            hasPreviousPage,
            loadingIndicator,
            children,
            scrollToBottom,
            ...props
        },
        forwardRef
    ) {
        const prevScrollHeight: MutableRefObject<number | null> = useRef<number>(null);
        const prevScrollTop: MutableRefObject<number | null> = useRef<number>(null);
        const prevAnchor: MutableRefObject<string | null> = useRef<string>(null);
        const { ref: prevRef, inView: prevInView } = useInView({
            initialInView: false,
            threshold: 1,
        });
        const { ref: nextRef, inView: nextInView } = useInView({
            initialInView: false,
            threshold: 1,
        });
        const {
            state: { scrollToBottomTrigger },
            dispatch,
        } = MessagingState();
        // React 19 will allow ref as prop, so refactor this when upgrading
        const ref = useRef<HTMLDivElement>(null);
        useImperativeHandle(forwardRef, () => ref.current as HTMLDivElement);

        useEffect(() => {
            if (prevInView) {
                if (ref.current) {
                    if (prevScrollTop.current && prevScrollHeight.current)
                        prevScrollTop.current += ref.current.scrollHeight - prevScrollHeight.current;
                    prevScrollHeight.current = ref.current.scrollHeight;
                }
                prevAnchor.current = window.crypto.randomUUID();
                fetchPreviousPage();
            }
            if (nextInView) {
                if (ref.current) {
                    prevScrollTop.current = ref.current.scrollTop;
                    prevScrollHeight.current = ref.current.scrollHeight;
                }
                fetchNextPage();
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [prevInView, nextInView]);

        useEffect(() => {
            if (scrollToBottom && ref.current && !prevScrollTop.current) {
                ref.current.scrollTop = ref.current.scrollHeight; //scroll to bottom on first render
            }
            if (ref.current && prevScrollHeight.current) {
                ref.current.scrollTop = ref.current.scrollHeight - prevScrollHeight.current; //restore scroll position for backwards scroll
            }
            // Mentioned in https://github.com/Apestein/better-react-infinite-scroll/issues/16 with no fix
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [prevAnchor.current, scrollToBottom]);

        useEffect(() => {
            // Scrolls to bottom when another component triggers it. Refactor this BiInfiniteScroller is needed in multiple places
            if (ref.current && scrollToBottomTrigger) {
                ref.current.scrollTop = ref.current.scrollHeight;
                dispatch({
                    type: ActionType.SCROLL_MESSAGE_LIST_TO_BOTTOM,
                    payload: false,
                });
            }
        }, [scrollToBottomTrigger, dispatch]);

        return (
            <div
                ref={ref}
                {...props}>
                {hasPreviousPage && loadingIndicator}
                <div
                    id="previous-loader"
                    className="center-loading-ref"
                    ref={prevRef}
                />
                {children}
                <div
                    id="next-loader"
                    className="center-loading-ref"
                    ref={nextRef}
                />
                {hasNextPage && loadingIndicator}
            </div>
        );
    }
);
