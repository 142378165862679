// Vendors
import { Icon } from '@vismaux/react-vud';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
// Context
import { areStringArraysEqual } from '../utils/utils';
import { useToast } from '@context/ToastContext';
import { ActionType } from '@context/ActionTypes';
import { MessagingState } from '@context/Context';
// Types
import { IUser } from '@appTypes/user';
import { IDiscussionThread, IDiscussionThreadWithMessageIds } from '@appTypes/discussionthread';
// Services
import RecipientService from '@services/RecipientService';
import DiscussionThreadService from '@services/DiscussionThreadService';
// Components
import WilmaAsyncSelect, { StringOption } from './WilmaAsyncSelect/WilmaAsyncSelect';

export interface QuickAddRecipientProps {
    discussionThread: IDiscussionThread | IDiscussionThreadWithMessageIds;
    setIsAddingRecipients: (flag: boolean) => void;
    recipients: IUser[];
    displayLimit: number;
    placeholder?: string;
    isAddingRecipients?: boolean;
    hiddenRecipientAmount?: number;
}

const QuickAddRecipients = ({
    discussionThread,
    setIsAddingRecipients,
    recipients,
    hiddenRecipientAmount,
    displayLimit,
    placeholder,
    isAddingRecipients,
}: QuickAddRecipientProps) => {
    const { t } = useTranslation();
    const { createToast } = useToast();
    const { dispatch } = MessagingState();
    const [messageSelectedRecipients, setMessageSelectedRecipients] = useState({ options: [] as StringOption[] });
    const [originalRecipients, setOriginalRecipients] = useState<StringOption[]>(mapIUserToOption(recipients));
    const {
        handleSubmit,
        control,
        formState: { errors, isSubmitting },
    } = useFormContext();

    const filterExistingRecipients = (recipientRecords: StringOption[], excludedRecipients: string[]) => {
        const excludedRoleGuids = excludedRecipients.map(record => record);
        return recipientRecords.filter(record => !excludedRoleGuids.includes(record.value));
    };

    useEffect(() => {
        const shouldInitialize = isAddingRecipients && messageSelectedRecipients.options.length === 0;
        const shouldReset =
            !isAddingRecipients &&
            !areStringArraysEqual(
                originalRecipients.map(x => x.value),
                messageSelectedRecipients.options.map(x => x.value)
            );
        if (shouldInitialize || shouldReset) {
            setMessageSelectedRecipients({ options: originalRecipients });
        }
    }, [isAddingRecipients, messageSelectedRecipients, originalRecipients]);

    // Simplify the valueToUse function
    const valueToUse = () => {
        if (isAddingRecipients) {
            return [...messageSelectedRecipients.options];
        } else {
            return originalRecipients;
        }
    };
    // sets isAddingRecipients to false when discussionThread changes
    useEffect(() => {
        setIsAddingRecipients(false);
    }, [discussionThread, setIsAddingRecipients]);

    useEffect(() => {
        setOriginalRecipients(mapIUserToOption(recipients));
    }, [recipients]);

    const promiseOptions = async (inputValue: string) => {
        if (!isAddingRecipients) {
            return { options: [], hasMore: false };
        }
        const response = await RecipientService.searchRecipients(inputValue);
        const records = response.data.allRecords.map(record => {
            const option: StringOption = {
                label: record.label,
                value: record.roleGuid,
                name: record.name,
                nameAbbreviation: record.nameAbbreviation,
                allowMessaging: record.allowSendingMessage,
                roleType: record.roleType,
                schoolNames: record.schoolNames,
                extraInfo: record.extraInfo,
                isGroup: false,
            };
            return option;
        });
        const filteredRecipients = filterExistingRecipients(
            records,
            discussionThread.recipients.map(x => x.roleGuid)
        ).filter(user => user.value !== discussionThread.author?.roleGuid);
        // hasMore false since API doesn't support pagination
        return { options: filteredRecipients, hasMore: false };
    };

    const submitDisabled = isSubmitting || messageSelectedRecipients.options.length === originalRecipients.length;

    const handleChange = (options: readonly StringOption[]) => {
        const latestOption = options[options.length - 1];
        const newRecipientsList = [latestOption, ...options.slice(0, options.length - 1)];
        setMessageSelectedRecipients({ options: newRecipientsList });
    };

    function mapIUserToOption(recipients: IUser[]): StringOption[] {
        return recipients.map(recipient => {
            const option: StringOption = {
                label: recipient.label,
                value: recipient.roleGuid,
                name: recipient.label,
                nameAbbreviation: '',
                allowMessaging: true,
                roleType: recipient.roleType,
                schoolNames: [],
                extraInfo: '',
                isGroup: false,
            };
            return option;
        });
    }

    const handleRecipientSubmit = async () => {
        try {
            const updatedDiscussionThread = await DiscussionThreadService.updateRecipientList(
                messageSelectedRecipients.options.map(o => o.value),
                discussionThread.id
            );
            dispatch({
                type: ActionType.UPDATE_DISCUSSION_THREAD,
                payload: updatedDiscussionThread.data,
            });
            dispatch({
                type: ActionType.SCROLL_MESSAGE_LIST_TO_BOTTOM,
                payload: true,
            });
        } catch (error) {
            createToast({
                title: t('errors.recipientModificationError'),
                toastType: 'danger',
            });
        }
        setIsAddingRecipients(false);
    };

    const placeholderProp = !placeholder ? t('placeholders.recipients') : isAddingRecipients ? placeholder : '';
    return (
        <div id="add-recipient-row">
            <form
                id="quick-add-recipients-form"
                onKeyDown={event => {
                    if (event.key === 'Enter' && !isAddingRecipients) {
                        event.preventDefault();
                    }
                }}
                onSubmit={handleSubmit(() => handleRecipientSubmit())}>
                <label
                    className="container-label"
                    aria-label={t('inputs.recipientsList')}>
                    <Controller
                        name="recipients"
                        control={control}
                        render={({ field: { onChange, onBlur } }) => (
                            <WilmaAsyncSelect
                                onBlur={onBlur}
                                disableAutofocus={true}
                                displayLimit={displayLimit}
                                value={valueToUse()}
                                recipients={recipients}
                                hiddenRecipientAmount={hiddenRecipientAmount}
                                ariaInvalid={errors.recipients ? 'true' : 'false'}
                                isSearchable={isAddingRecipients}
                                placeholder={placeholderProp}
                                loadingMessage={() => t('loading')}
                                loadOptions={promiseOptions}
                                hideMenu={!isAddingRecipients}
                                hideRemoveButton={!isAddingRecipients}
                                ariaLabel={!placeholder ? t('placeholders.recipients') : placeholder}
                                noOptionsMessage={() => t('noRecipientsFound')}
                                readonlyUsers={originalRecipients.map(x => x.value)}
                                onChange={e => {
                                    handleChange(e);
                                    onChange(e);
                                }}
                                hasErrors={errors.recipients ? true : false}
                            />
                        )}
                    />
                </label>
                {isAddingRecipients && (
                    <button
                        id="quick-recipient-add-submit-button"
                        type="submit"
                        disabled={submitDisabled}
                        className="btn btn-primary bg-secondary action-button icon-button"
                        aria-disabled={submitDisabled}
                        aria-label={t('buttons.submitNewRecipients')}>
                        <Icon
                            name="ok-circle"
                            size="lg"
                        />
                    </button>
                )}
                {isSubmitting && <span className="spinner spinner-sm spinner-default-blue"></span>}
            </form>
        </div>
    );
};

export default QuickAddRecipients;
